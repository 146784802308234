<template>
  <div id="outstanding">
    <div class="Implement-add">
      <div class="adds" @click="addfn(1)">添加</div>
    </div>

    <div class="Implement-wrap">
      <div
        class="Implement-list"
        v-if="listdata.length"
        v-for="(item, index) in listdata"
        :key="index"
      >
        <div class="title-02 textOverflowHide">
          <img :src="baseUrl + item.ImgUrl" alt="" />
          <div class="image-wrap-del" @click="delfn(item)">&times;</div>
          <div class="edit-b" @click="addfn(2,item)">编辑</div>
        </div>

        <div class="title-03 textOverflowHide">{{ item.StaffName }}</div>
      </div>
      <div class="notdata" v-else>暂无数据</div>
    </div>
    <div class="pages" v-if="total && total > pageSizes">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pageSizes"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <addProjectpeople
      v-if="addPfalse"
      :sqtype="sqtype"
      :addPtitle="addPtitle"
      :editdata="editdata"
      @addPfalsefn="addPfalsefn"
      @GetProjectBigEventList="GetProjectBigEventList"
    ></addProjectpeople>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ElMessage, ElMessageBox } from "element-plus";
import addProjectpeople from "@/components/addProjectpeople.vue";

import { GetStaffList, DeleteStaff } from "@/js/peoples";
import { getStorage } from "@/js/common";
import { baseUrl } from "@/js/util.js";
import { DelPictureList } from "@/js/indexApi.js";
export default {
  name: "",
  props: {},

  setup(props) {
    const state = reactive({
      baseUrl: baseUrl,
      total: 0,
      currentPage: 1,
      pageSizes: 20,
      addPfalse: false,
      addPtitle: "",
      listdata: [],
      editdata: "",
      sqtype: 1, //安全：1，质量：2，
    });
    const cdata = getStorage("bscdata");

    const delDoc = (docs) => {
      // 删除文件
      DelPictureList(docs).then((res) => {});
    };

    const methods = {
      // 添加弹框
      addfn: (id, item) => {
        console.log(121212);
        if (id == 1) {
          state.addPtitle = "添加优秀员工";
        } else if (id == 2) {
          state.addPtitle = "编辑优秀员工";
          state.editdata = item;
          console.log(state.editdata, "state.editdata");
        }

        state.addPfalse = !state.addPfalse;
      },
      //添加弹框 关闭弹框
      addPfalsefn() {
        state.addPfalse = false;
      },
      // 列表获取分页数据
      handleCurrentChange: (val) => {
        state.currentPage = val;
        methods.GetProjectBigEventList();
      },

      // 列表

      GetProjectBigEventList: () => {
        let datas = {
          ProjectID: cdata.pid,
          pageIndex: state.currentPage,
          pageSize: state.pageSizes,
        };
        GetStaffList(datas).then((res) => {
          // 添加工程图片
          console.log(res, "list");
          if (res.data.Code == 1) {
            state.listdata = res.data.Data.data;
            state.total = res.data.Data.total.Value;
          } else {
            ElMessage({
              showClose: true,
              message: res.data.message,
              type: "error",
            });
          }
        });
      },
      dels(item) {
        let datas = {
          ID: item.ID,
        };
        DeleteStaff(datas).then((res) => {
          if (res.data.Code == 1) {
            ElMessage({
              showClose: true,
              message: res.data.Message,
              type: "success",
            });
            let imgs = [];
            imgs.push(item.ImgUrl);
            delDoc(imgs);
            methods.GetProjectBigEventList();
          } else {
            ElMessage({
              showClose: true,
              message: res.data.Message,
              type: "error",
            });
          }
        });
      },
      // 删除项目大事记
      delfn(item) {
        ElMessageBox.confirm("此操作将永久删除该文件, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            methods.dels(item);
          })
          .catch((error) => {});
      },
    };

    onMounted(() => {
      methods.GetProjectBigEventList();
    });

    return {
      ...methods,
      ...toRefs(state),
    };
  },

  components: {
    addProjectpeople,
  },
};
</script>

<style lang='scss' scoped>
#outstanding {
  margin: 0 30px;
  box-sizing: border-box;
  .edit-b{
    position:absolute;
    font-size:14px;
    color:#333;
    right:0px;
    top:40px;
    cursor:pointer;

  }
  .Implement-add {
    height: 76px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    box-sizing: border-box;
    .adds {
      width: 66px;
      height: 36px;
      background: #0083ff;
      border-radius: 5px;
      text-align: center;
      line-height: 36px;
      font-size: 14px;
      color: #fff;
      cursor: pointer;
    }
  }

  .Implement-wrap {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-wrap: wrap;
    .Implement-list {
      // width: 9%;
      margin-right: 3%;
      margin-bottom: 3%;
      box-sizing: border-box;
      height: 24%;
      position: relative;
      .title-02 {
        width: 100%;
        height: 94%;
        img {
          width: 145px;
          height: 208px;
          object-fit: cover;
        }
      }
      .image-wrap-del {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.6);
        line-height: 30px;
        color: #fff;
        font-size: 18px;
        float: right;
        // position: absolute;
        // top: -12px;
        // right: 20px;
        cursor: pointer;
        line-height: 30px;
        text-align: center;
      }
      .title-03 {
        width: 145px;
        height: 6%;
        text-align: center;
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: #555555;
      }
    }
  }

  .pages {
    margin-top: 20px;
    box-sizing: border-box;
    float: right;
  }
}
</style>