<template>
  <div id="addProjectpeople">
    <div class="addProjects-wrap">
      <div class="addProjects-top">
        <div>{{ addPtitle }}</div>
        <img @click="closefn"
             src="@/assets/images/pop01.png"
             alt="" />
      </div>
      <div class="addProjects-content">
        <div class="addProjects-input">
          <span>人员姓名</span>
          <div>
            <el-input v-model="inputs1"
                      maxlength="20"
                      placeholder="请输入人员姓名"></el-input>
          </div>
        </div>
      </div>

      <div class="addProjects-content">
        <div class="addProjects-input">
          <span>照片</span>
          <div class="img-list">
            <el-upload class="picture-card"
                       :multiple="true"
                       :before-upload="beforeUpload"
                       accept="image/jpg,image/jpeg,image/png"
                       :action="
                baseUrl +
                `/Intelligence_Building_API/WeatherForecast/SavePictureList?type=7`
              "
                       :on-success="successfn"
                       :class="{ hiddenBtn: filelist.length >= 1 }"
                       :file-list="filelist"
                       list-type="picture-card"
                       :on-preview="handlePictureCardPreview"
                       :on-remove="handleRemove"
                       :limit="1">
              <img src="@/assets/images/common/cicon09.png"
                   alt=""
                   class="up-icon" />
            </el-upload>
          </div>
          <div class="list-title">(图片最大不超过3M)</div>
        </div>
      </div>

      <div class="addProjects-content">
        <div class="addProjects-input addProjects-input02">
          <span>人员信息</span>
          <div>
            <el-input v-model="inputs2"
                      show-word-limit
                      type="textarea"
                      placeholder="请输入人员信息"
                      :rows='5'></el-input>
          </div>
        </div>
      </div>

      <div class="addProjects-bottom">
        <button @click="closefn">取消</button>
        <button @click="AddExtractApplyfn"
                :plain="true"
                class="btns"
                :class="!inputs1 || !filelist.length ? 'def' : ''">
          确定
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted, onBeforeUnmount } from "vue";
import { ElMessage } from "element-plus";
import { getStorage } from "@/js/common";
import Ewang from "wangeditor";
import { baseUrl } from "@/js/util.js";

import {
  UpdateSecurityBigEvent,
  GetSecurityBigEvent,
  UpdateQualityTemplet,
  GetQualityTemplet,
} from "@/js/safeDisclose";
import { UpdateStaff } from "@/js/peoples";
export default {
  props: {
    addPfalse: Boolean,
    bonus: Number,
    addPtitle: String,
    editdata: Object,
    sqtype: Number,
  },
  setup (props, { emit }) {
    const state = reactive({
      sqtype: "",
      editor: null,
      baseUrl: baseUrl,
      inputs1: "", //
      inputs2: "",
      inputs3: "",
      value: "",
      Primarydata: [],
      Primaryobj: null,
      adding: false,
      addPtitle: "",
      editid: "",
      num: 0,
      showstyle: false,
      filelist: [],
      fileimg: null,
      tableDatas: [
        {
          inputs1: "", //
          inputs2: "",
        },
      ],
    });
    const closefn = () => {
      emit("addPfalsefn");
    };

    const cdata = getStorage("bscdata");

    const methods = {
      // 图片
      beforeUpload: (file) => {
        const is1M = file.size / 1024 / 1024 < 3; // 限制小于3M

        if (!is1M) {
          ElMessage({
            showClose: true,
            message: "大小不可超过3M",
            type: "error",
          });
        }
        return is1M;
      },
      // 上传图片
      successfn: (response, file, fileList) => {
        // 图片上传成功
        state.updatalistnum++;
        console.log(response, file, fileList);
        state.filelist = fileList;
        state.fileimg = response.join(",");
        state.imglist = [];
      },
      handlePictureCardPreview: (file) => {
        // 图片预览 没写功能
        console.log(file, file.url);
        state.dialogImageUrl = file.url;
        state.dialogVisible = true;
      },
      handleRemove: (file, fileList) => {
        // 删除图片
        state.updatalistnum++;
        let datas = [...file.response];
        state.filelist = fileList;
        state.imglist = [];
        // DeleteFile(datas);
      },

      // 添加项目大事记
      UpdateSecurityBigEvent: () => {
        let datas = {};
        if (state.addPtitle == "编辑优秀员工") {
          datas = {
            id: state.editid,

            staffName: state.inputs1,
            content: state.inputs2,
            imgUrl: state.fileimg,

            projectID: cdata.pid,
          };
        } else {
          datas = {
            staffName: state.inputs1,
            content: state.inputs2,
            imgUrl: state.fileimg,

            projectID: cdata.pid,
          };
        }

        console.log(
          datas,
          "datas",
          state.filelist.join(","),
          state.filelist[0].response[0]
        );

        UpdateStaff(datas).then((res) => {
          console.log(res);
          if (res.data.Code == 1) {
            ElMessage({
              showClose: true,
              message: res.data.Message,
              type: "success",
            });
            emit("addPfalsefn");
            emit("GetProjectBigEventList");
          } else {
            ElMessage({
              showClose: true,
              message: res.data.Message,
              type: "error",
            });
          }
        });
      },

      AddExtractApplyfn: () => {
        if (!state.inputs1 || !state.filelist.length) {
          return;
        }

        methods.UpdateSecurityBigEvent();
      },
      // 项目大事记详情
      GetProjectBigEvent: () => {
        let datas = {
          ID: state.editid,
        };

        console.log(datas, "datas");

        GetQualityTemplet(datas).then((res) => {
          console.log(res);
          if (res.data.Code == 1) {
            state.inputs1 = res.data.Data[0].Title;
            state.editor.txt.html(res.data.Data[0].Content);
            // state.filelist= res.data.Data.data.ImgUrl.split(',')

            let obj = {
              name: res.data.Data[0].ID,
              url: baseUrl + res.data.Data[0].ImgUrl,
              response: [res.data.Data[0].ImgUrl],
            };
            state.filelist.push(obj);
            console.log(state.filelist, 1212);
          } else {
            ElMessage({
              showClose: true,
              message: res.data.Message,
              type: "error",
            });
          }
        });
      },
    };

    onMounted(() => {
      state.sqtype = props.sqtype;
      state.addPtitle = props.addPtitle;
      if (state.addPtitle == "编辑优秀员工") {
        state.editid = props.editdata.ID;
        state.inputs1 = props.editdata.StaffName;
        state.inputs2 = props.editdata.Content;
        state.fileimg = props.editdata.ImgUrl;
        state.filelist = [{
          response: [props.editdata.ImgUrl],
          name: props.editdata.ImgUrl,
          url: baseUrl + props.editdata.ImgUrl
        }]
        console.log(props.editdata);
      }
    });
    onBeforeUnmount(() => { });
    return {
      ...methods,
      closefn,
      ...toRefs(state),
    };
  },
};
</script>
<style lang="scss" scoped>
#addProjectpeople {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  .addProjects-wrap {
    width: 656px;
    min-height: 235px;
    background: #ffffff;
    border-radius: 4px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    .addProjects-top {
      padding: 0 20px;
      width: 100%;
      height: 50px;
      border-bottom: 1px solid #e3edf8;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      font-size: 16px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #666666;
      img {
        width: 32px;
        height: 32px;
        cursor: pointer;
      }
    }
  }

  .add-wrap {
    max-height: 500px;
    overflow-y: auto;
  }

  .addProjects-content {
    width: 100%;
    padding: 0 20px 20px;
    // border-bottom: 1px solid #e3edf8;
    box-sizing: border-box;

    display: flex;
    justify-content: left;
    align-items: center;
    .annotation {
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #00a5ff;
      text-align: center;
      margin-top: 5px;
      margin-left: 20px;
      box-sizing: border-box;
    }
    .addProjects-input {
      display: flex;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #555555;
      margin-top: 20px;
      box-sizing: border-box;
      align-items: center;
      position: relative;
      &:deep(.img-list) {
        width: 81px;
        height: 63px;
        .el-upload-list--picture-card {
          .el-upload-list__item {
            width: 81px;
            height: 63px;
            border-radius: 0;
          }
          .el-upload-list__item-actions:hover
            span.el-upload-list__item-preview {
            display: none;
          }
        }
        .el-upload--picture-card {
          width: 81px;
          height: 63px;
          position: relative;
          background: #f3f3f3;
          border: 1px dashed #999999;
          border-radius: 0;
          .up-icon {
            width: 34px;
            height: 35px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
          i {
            position: absolute;
            right: 12px;
            bottom: 8px;
            font-size: 13px;
            color: #999999;
            display: inline-block;
            height: 20px;
            line-height: 20px;
          }
        }

        .hiddenBtn {
          .el-upload--picture-card {
            display: none;
          }
        }
      }
      .list-title {
        position: absolute;
        left: 186px;
        bottom: 2px;
      }

      span {
        display: inline-block;
        width: 90px;
        padding-right: 11px;
        box-sizing: border-box;
        text-align: right;
      }
      div {
        width: 337px;
      }
    }
    .addProjects-input02 {
      width: 100%;
      div {
        width: 500px;
      }

      #div1 .w-e-toolbar {
        z-index: 100 !important;
      }
      #div1 .w-e-text-container {
        z-index: 99 !important;
        height: 150px !important;
      }
    }
    .dels {
      width: 30px;
      height: 30px;
      display: flex;
      margin-top: auto;
    }

    .addProjects-input1 {
      margin: 20px 20px 0;
      box-sizing: border-box;
      div {
        width: 100px;
      }
    }
  }

  .addProjects-bottom {
    text-align: right;
    padding: 10px 20px;
    box-sizing: border-box;
    border-top: 1px solid #c6c6c6;
    button {
      display: inline-block;
      width: 68px;
      height: 36px;
      border-radius: 4px;
      border: 1px solid #c6c6c6;
      line-height: 36px;
      text-align: center;
      font-size: 14px;
      font-family: MicrosoftYaHei;

      margin-left: 10px;
      box-sizing: border-box;
      cursor: pointer;

      &:nth-child(2) {
      }
    }

    .btns {
      background: #027aff;
      color: #fff;
    }

    .def {
      background: rgb(239, 239, 239);
      color: #adbaae;
      cursor: default;
    }
  }
}
</style>