import axios from 'axios';

import {baseUrl} from '@/js/util.js'

// 新增修改优秀员工
export const UpdateStaff=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/Staff/UpdateStaff`,
        data:datas
    })
}



// 获取优秀员工列表
export const GetStaffList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/Staff/GetStaffList`,
        params:datas
    })
}


// 获取优秀员工详情
export const GetStaff=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/Staff/GetStaff`,
        params:datas
    })
}

// 删除优秀员工
export const DeleteStaff=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/Staff/DeleteStaff`,
        params:datas
    })
}

